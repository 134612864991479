import { connect } from "react-redux";
import { DashboardFetchRequest } from "./action";
import Dashboard from "./Dashboard";
import { withNavigation } from "../common/withNavigation";

const mapStateToProps = (state) => {
//   console.log("dashboarddata----->", state.Dashboard.dashboardData);
  return {
    ...state,
    DashbordData : state.Dashboard.dashboardData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dashboardRequest: () => dispatch(DashboardFetchRequest()),
  };
};

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
