import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';  

// Import the logo image
import logo from '../Assets/Logo/Logo_13_1.png';  // Correct relative path
import notifIcon from '../Assets/icons/notif.png'; // Bell icon
import profilePic from '../Assets/icons/Profil.png'; // Profile image
import Modal from '../common/Modal';
import { connect } from 'react-redux';  // Import redux connect
import { logoutRequest } from '../Login/action';  // Import logout action

const Header = ({ logoutRequest, user }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState(false);
  const navigate = useNavigate();
  console.log('token',token)

  useEffect(() => {
    // Check if there's a token in localStorage, if so, set token state to true
    const authToken = localStorage.getItem('token');
    if (authToken !== null) {
      setToken(true);
    } else {
      navigate('/login'); // Redirect to login page if no token
    }
  }, [navigate]);

  const handleLogout = () => {
    setIsModalOpen(true); // Open logout confirmation modal
  };

  const confirmLogout = () => {
    logoutRequest(); // Trigger the logout action
    setToken(false); // Update token state to reflect that the user is logged out
    setIsModalOpen(false); // Close the modal
    navigate('/login'); // Redirect to login page after logout
  };

  const cancelLogout = () => {
    setIsModalOpen(false); // Close the modal without logging out
  };

  const handleLogin = () => {
    navigate('/login'); // Redirect to login page
  };

  return (
    <>
      {/* Logo placed outside of the header */}
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>

      {/* Header section without logo */}
      <header className="header">
        {/* Header content can go here */}
      </header>
  
      {/* Navigation group placed outside of the header */}
      <nav className="nav-container">
        <a href="#home" className="home">Home</a>
        <a href="#programs" className="program">Programs</a>
        <a href="#about" className="about">About Us</a>
        <a href="#contact" className="contact">Contact Us</a>
      </nav>

      {/* Icons container placed outside of the header */}
      <div className="icons-container">
        {/* Bell icon */}
        <a href="#notifications" className="notif-icon">
          <img src={notifIcon} alt="Notifications" className="icon" />
        </a>

        {/* Profile picture */}
        <a href="#profile" className="profile-icon">
          <img src={profilePic} alt="Profile" className="icon" />
        </a>

        {/* Conditionally render logout/login button */}
        {token ? (
          <button className="logout-button" onClick={handleLogout}>Logout</button>
        ) : (
          <button className="login-button" onClick={handleLogin}>Login</button>
        )}
      </div>  

      {/* Modal for logout confirmation */}
      <Modal isOpen={isModalOpen} onClose={cancelLogout}>
        <p>Are you sure you want to logout?</p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10%' }}>
          <button className='logout-yes-btn' onClick={confirmLogout}>Yes</button>
          <button className='logout-no-btn' onClick={cancelLogout}>No</button>
        </div>
      </Modal>
    </>
  );
};

// Connect Redux state and dispatch to props

const mapStateToProps = (state) => {
  // console.log('userlogout', state.login.token)
  return {
    user: state.login.user,
    Bannerdata:state.login.banner.data,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    logoutRequest: () => dispatch(logoutRequest()), // Dispatch logout action
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
