import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import login from '../Login/Login-reducer';  // Import the login reducer
import createSagaMiddleware from 'redux-saga';
import Dashboard from '../Dashboad/Dashboard-Reducer';
import { rootSaga } from '../Root';  // If the login feature uses sagas

// Combine only the login reducer
const rootReducer = combineReducers({
    login,
    Dashboard
});

// Setup saga middleware (if needed for login)
const sagaMiddleware = createSagaMiddleware();

// Create the Redux store with middleware
const store = createStore(rootReducer, applyMiddleware(thunk, sagaMiddleware));

// Run sagas if any related to login
sagaMiddleware.run(rootSaga);

export default store;
