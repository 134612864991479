import React, { Component } from "react";
import Dashboardpage from "../pages/Dashboard";

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dashboardRequest } = this.props;
    dashboardRequest();
  }
  render() {
    const { DashbordData } = this.props;
    return (
      <>
        <Dashboardpage DashbordData={DashbordData} />
      </>
    );
  }
}

export default Dashboard;
