import React, { useState, useEffect } from 'react';
import './rightbar.css'; // Import the CSS file

const RightBar = ({ Dashboarddata }) => {
  // State to manage the expanded/collapsed state of each section
  const [expandedLeaderboard, setExpandedLeaderboard] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [leaderboardTitle, setLeaderboardTitle] = useState("");

  useEffect(() => {
    // Log the entire Dashboarddata to inspect the structure
    console.log('Dashboarddata:', Dashboarddata);

    if (Dashboarddata !== undefined) {
      if (Dashboarddata.HOME && Dashboarddata.HOME.RIGHT_SIDE) {
        const rightSide = Dashboarddata.HOME.RIGHT_SIDE;

        // Log the rightSide to verify if it's correct
        console.log('rightSide:', rightSide);

        // Check if data exists in RIGHT_SIDE for the "Leaderboard"
        if (rightSide.length > 0) {
          // Try to find the "Leaderboard" section, log the titles in rightSide for debugging
          console.log('Titles in rightSide:', rightSide.map(item => item.title));

          const leaderboardSection = rightSide.find(item => item.title === "Leaderboard");
          
          if (leaderboardSection) {
            console.log('Leaderboard Section:', leaderboardSection);  // Log the matched section
            setLeaderboardTitle(leaderboardSection.title);
            setLeaderboardData(leaderboardSection.data);
          } else {
            console.log("No Leaderboard section found, using fallback.");
            setLeaderboardTitle("Leaderboard");  // Fallback title if no section found
            setLeaderboardData([]);  // No data to display if section is missing
          }
        } else {
          console.log("RIGHT_SIDE is empty or undefined.");
        }
      }
    }
  }, [Dashboarddata]);

  // Helper function to render "See all" or "Show less"
  const renderToggleButton = (toggleState, setToggleState) => {
    return (
      <button
        onClick={() => setToggleState(!toggleState)}
        className="see-all-btn"
      >
        {toggleState ? "Show less" : "See all"}
      </button>
    );
  };

  return (
    <div className="rightbar">
      {leaderboardData.length > 0 && (
        <div className="rightbar-section">
          <div
            className="rightbar-title"
            onClick={() => setExpandedLeaderboard(!expandedLeaderboard)}
          >
            {leaderboardTitle}
          </div>
          <div className="rightbar-content">
            {leaderboardData.slice(0, expandedLeaderboard ? leaderboardData.length : 5).map((item, index) => (
              <div key={index}>
                <p>{item.name}</p>  {/* Displaying the name */}
              </div>
            ))}
            {leaderboardData.length > 5 && renderToggleButton(expandedLeaderboard, setExpandedLeaderboard)}
          </div>
        </div>
      )}
    </div>
  );
};

export default RightBar;
