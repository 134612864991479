import { all, fork } from 'redux-saga/effects';
import * as loginSaga from './Login/Login-Saga';
import * as Dashboardsaga from './Dashboad/Dashboard-saga'

export function* rootSaga() {
  const LoginSage = Object.values(loginSaga).map((saga) => {
    return fork(saga);
  });

  const dashboard = Object.values(Dashboardsaga).map((saga) => {
    return fork(saga);
  });

  yield all([
    ...LoginSage,
    ...dashboard
  ]);
}
