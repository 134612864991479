import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./homeCenter.css"; // Import the CSS for layout and styling

const PieChart = ({ Dashboarddata }) => {
  const [charGarphs, setcharGarphs] = useState([]);

  // Extract and bind the data when Dashboarddata changes
  useEffect(() => {
    if (Dashboarddata && Dashboarddata.HOME && Dashboarddata.HOME.CENTER) {
      setcharGarphs(Dashboarddata.HOME.CENTER);
    }
  }, [Dashboarddata]);

  return (
    <div className="container">
      {charGarphs.map((item, index) => {
        if (item.isGraph === 1) {
          // Render Pie/Column chart
          return (
            <div key={index} className="chart-wrapper">
              <HighchartsReact highcharts={Highcharts} options={item.data} />
            </div>
          );
        } else {
          // Render table if isGraph is not 1
          return (
            <div key={index} className="table-wrapper">
              <h3>{item.title}</h3>
              <table className="data-table">
                <thead>
                  <tr>
                    {/* Dynamically generate table headers based on the keys of the first row */}
                    {item.data[0] &&
                      Object.keys(item.data[0]).map((key) => (
                        <th key={key}>{key.replace(/_/g, " ").toUpperCase()}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {item.data.map((row) => (
                    <tr key={row.id}>
                      {/* Dynamically generate table cells based on the row's keys */}
                      {Object.keys(row).map((key) => (
                        <td key={key}>{row[key] || "N/A"}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
        }
      })}
    </div>
  );
};

export default PieChart;
