import React, { useState, useEffect } from "react";
import "./cards.css";

const Card = ({ title, isOdd, counts }) => {
  const [flipStage, setFlipStage] = useState(false);
  const [isFlipping, setIsFlipping] = useState(false);

  const handleMouseEnter = () => {
    if (!isFlipping && !flipStage) {
      setIsFlipping(true);
      setFlipStage(true);
    }
  };

  const handleMouseLeave = () => {
    if (flipStage) {
      setIsFlipping(false);
      setFlipStage(false);
    }
  };

  const renderContent = () => {
    return flipStage ? (
      <span className="text">Dashboard</span>
    ) : (
      title
    );
  };

  const getCardColor = () => {
    return flipStage ? "#fff" : isOdd ? "#E3F5FF" : "#E5ECF6";
  };

  return (
    <div
      className={`card ${title !== "Blank" ? "flip-card" : ""} ${isFlipping ? "flip-animation" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ backgroundColor: getCardColor() }}
    >
      <div className="card-title">{renderContent()}</div>

      {/* Render the 'counts' value */}
      {!flipStage && (
        <>
          <div className="card-top-right">{counts}</div> {/* Display 'counts' */}
          <div className="card-footer">
            <div className="stat-box">
              <span className="stat-label">Weekly</span>
              <div className="stat-change positive">
                <span>7%</span>
                <span className="arrow up"></span>
              </div>
            </div>
            <div className="stat-box">
              <span className="stat-label">Monthly</span>
              <div className="stat-change negative">
                <span>6%</span>
                <span className="arrow down"></span>
              </div>
            </div>
            <div className="stat-box">
              <span className="stat-label">Yearly</span>
              <div className="stat-change negative">
                <span>5.9%</span>
                <span className="arrow down"></span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const CardLayout = ({ Dashboarddata }) => {
  const [cardNames, setCardNames] = useState([]);
  const [cardCounts, setCardCounts] = useState([]); // State to hold counts

  useEffect(() => {
    console.log("Dashboarddatas", Dashboarddata);
    if (Dashboarddata && Dashboarddata.HOME && Dashboarddata.HOME.CARDS) {
      // Extract titles and counts from the Dashboarddata
      const titles = Dashboarddata.HOME.CARDS.map((card) => card.title); // Extract titles
      const counts = Dashboarddata.HOME.CARDS.map((card) => card.data[0].counts); // Extract 'counts' from the 'data' array
      setCardNames(titles); // Set titles
      setCardCounts(counts); // Set counts
    }
  }, [Dashboarddata]); // Re-run effect when Dashboarddata changes

  return (
    <div className="card-container">
      {cardNames.map((title, index) => (
        <Card
          key={index}
          title={title}
          isOdd={index % 2 === 0}
          counts={cardCounts[index]} // Pass counts to Card component
        />
      ))}
    </div>
  );
};

export default CardLayout;
