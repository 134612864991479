import {
  DASHBOARD_DATA_FETCH_REQUEST,
  DASHBOARD_DATA_FETCH_SUCCESS,
  DASHBOARD_DATA_FETCH_FAILURE,
} from "./actionType";


export const DashboardFetchRequest = (params) => ({
    type: DASHBOARD_DATA_FETCH_REQUEST,
    payload: params,
  });
  
  export const DashboardFetchSuccess = (data) => ({
    type: DASHBOARD_DATA_FETCH_SUCCESS,
    payload: data,
  });
  
  export const DashboardFetchfailure = (error) => ({
    type: DASHBOARD_DATA_FETCH_FAILURE,
    payload: error,
  });