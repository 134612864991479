import React, { useState, useEffect } from "react";
import "./sidebar.css"; // Importing the CSS file

const Sidebar = ({ Dashboarddata }) => {
  // State to manage the expanded/collapsed state of each section
  const [expandedCampaigns, setExpandedCampaigns] = useState(false);
  const [expandedContent, setExpandedContent] = useState(false);
  const [expandedHCPs, setExpandedHCPs] = useState(false);
  const [DashboardData, setdashboarddata] = useState([]);
  const [campaignsdata, setcampaignsdata] = useState([]);
  const [Content, setContent] = useState([]);
  const [Title, setTitle] = useState("");
  const [contentTitle, setcontentTitle] = useState("");
  const [hcp, sethcp] = useState([]);
  const [hcptitle, sethcptitle] = useState("");

  useEffect(() => {
    if (Dashboarddata !== undefined) {
      setdashboarddata(Dashboarddata.HOME);
      if (Dashboarddata.HOME) {
        if (Dashboarddata.HOME.LEFT_SIDE.length > 0) {
          setTitle(Dashboarddata.HOME.LEFT_SIDE[0].title);
          setcampaignsdata(Dashboarddata.HOME.LEFT_SIDE[0].data);
        }
        if (Dashboarddata.HOME.LEFT_SIDE.length > 1) {
          setcontentTitle(Dashboarddata.HOME.LEFT_SIDE[1].title);
          setContent(Dashboarddata.HOME.LEFT_SIDE[1].data);
        }
        if (Dashboarddata.HOME.LEFT_SIDE.length > 2) {
          sethcptitle(Dashboarddata.HOME.LEFT_SIDE[2].title);
          sethcp(Dashboarddata.HOME.LEFT_SIDE[2].data);
        }
      }
      // if (Dashboarddata.HOME) {
      //   setTitle(Dashboarddata.HOME.LEFT_SIDE[0].title);
      //   setcampaignsdata(Dashboarddata.HOME.LEFT_SIDE[0].data);
      // }
      // if (Dashboarddata.HOME.LEFT_SIDE.length > 1) {
      //   setContent(Dashboarddata.HOME.LEFT_SIDE[1].data);
      // }
    }
  }, [Dashboarddata]);

  // Helper function to render "See all" or "Show less"
  const renderToggleButton = (section, toggleState, setToggleState) => {
    return (
      <button
        onClick={() => setToggleState(!toggleState)}
        className="see-all-btn"
      >
        {toggleState ? "Show less" : "See all"}
      </button>
    );
  };

  return (
    <div className="sidebar">
      {campaignsdata.length > 0 && (
        <div className="sidebar-section">
          <div
            className="sidebar-title"
            onClick={() => setExpandedCampaigns(!expandedCampaigns)}
          >
            {Title}
          </div>
          <div className="sidebar-content">
            {campaignsdata.map((item, index) => (
              <div key={index}>
                <p>{item.name}</p>
              </div>
            ))}
            {/* {expandedCampaigns && campaigns.slice(6).map((item, index) => (
            <div key={index + 6}>{item}</div>
          ))}
          {campaigns.length > 6 && renderToggleButton('campaigns', expandedCampaigns, setExpandedCampaigns)} */}
          </div>
        </div>
      )}
      {Content.length > 0 && (
        <div className="sidebar-section">
          <div
            className="sidebar-title"
            onClick={() => setExpandedContent(!expandedContent)}
          >
            {contentTitle}
          </div>
          <div className="sidebar-content">
            {Content.map((item, index) => (
              <div key={index}>{item.name}</div>
            ))}
            {/* {expandedContent &&
      content
        .slice(6)
        .map((item, index) => <div key={index + 6}>{item}</div>)}
    {content.length > 6 &&
      renderToggleButton("content", expandedContent, setExpandedContent)} */}
          </div>
        </div>
      )}
      {hcp.length > 0 && (
        <div className="sidebar-section">
          <div
            className="sidebar-title"
            onClick={() => setExpandedHCPs(!expandedHCPs)}
          >
            {hcptitle}
          </div>
          <div className="sidebar-content">
            {hcp.map((item, index) => (
              <div key={index}>{item.name}</div>
            ))}
            {/* {expandedHCPs &&
            hcps
              .slice(6)
              .map((item, index) => <div key={index + 6}>{item}</div>)}
          {hcps.length > 6 &&
            renderToggleButton("hcps", expandedHCPs, setExpandedHCPs)} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
