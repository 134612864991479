import React from 'react';
import Navigation from './Navigation/Navigation';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './Store/Store';
// import Login from './pages/Login';
// import Dashboard from './pages/Dashboard';
// import './App.css';

// Define App component with routes as a separate component
// const App = () => {
//   return (
//     <div className="app">
//       <Routes>
//         <Route path="/" element={<Login />} />
//         <Route path="/dashboard" element={<Dashboard />} />
//       </Routes>
//     </div>
//   );
// };

// // Main render to mount the App component inside the root div
// const Main = () => (
//   <Router>
//     <App />
//   </Router>
// );

// export default Main;

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Navigation />
      </Router>
    </Provider>
  );
}

export default App;


