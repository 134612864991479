import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { BANNER_REQUEST, LOGIN_REQUEST, LOGOUT_REQUEST} from './actionType';
import {
    loginSuccess,loginFailure,
    BannerSuccess,
    BannerFailure
} from './action';
import { Api } from '../common/Api';
import { logoutSuccess, logoutFailure } from './action';
import axiosInstance from '../common/AxiosConfig';

// const params = new URLSearchParams();
//     for (const key in action.payload) {
//       if (action.payload.hasOwnProperty(key)) {
//         params.append(key, action.payload[key]);
//       }
//     }
function* loginSaga(action) {
  console.log('Loginrequest====>', action.payload)
  try {
    const params = {
      email : action.payload.email,
      password: action.payload.password,
    }
    const response = yield call(axios.post, `${Api.Apiurl}dashboard/login`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    
    localStorage.setItem('token', response.data.token);
    yield put(loginSuccess({
      user: response.data, 
      token: response.data.token,
    }));
    console.log('sucess--->', response.data)
  } catch (error) {
    console.log('failure--->', error)
    yield put(loginFailure(error.message));
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
}


// function* logoutSaga() {
//   try {
//     localStorage.removeItem('token');
//     yield put(logoutSuccess());
//   } catch (error) {
//     yield put(logoutFailure(error.message));
//   }
// }

// export function* watchLogout() {
//   yield takeLatest(LOGOUT_REQUEST, logoutSaga);
// }



// function* BannerSaga(action) {
//   const token = localStorage.getItem('token')
//   const params = {
//     limit:3,
//   }
//   try {
//     const apiUrl = token ? `external/home-user` : `/external/home`;
//     const response = yield call(axiosInstance.post, apiUrl, params, {
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//       },
//     });
//     yield put(BannerSuccess(response.data));

//   } catch (error) {
//     yield put(BannerFailure(error.message));
//   }
// }

// export function* watchBanner() {
//   yield takeLatest(BANNER_REQUEST, BannerSaga);
// }