import axiosInstance from "../common/AxiosConfig";
import { put, takeEvery, call } from "redux-saga/effects";
import { DASHBOARD_DATA_FETCH_REQUEST } from "./actionType";
import { DashboardFetchSuccess, DashboardFetchfailure } from "./action";


function* DashboardSaga(action){
    try{
        const responce = yield call (axiosInstance.post,`dashboard/home`,action.payload,{
        })
        yield put (DashboardFetchSuccess(responce.data))
        // console.log('success=====>', responce.data)
    } catch (error){
        // console.log('error===>', error)
        yield put (DashboardFetchfailure(error))
    }
}

export function* dashboardsaga(){
    yield takeEvery(DASHBOARD_DATA_FETCH_REQUEST,DashboardSaga)
}