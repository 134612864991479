import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import LoginRedux from '../Login/Login-redux';
import Dashboard from '../pages/Dashboard'
import DashboardRedux from '../Dashboad/Dashboard-redux';

function Navigation() {
    return (
        <div>
            <Routes>
                <Route path='/login' element={<LoginRedux />} />
                <Route path='/' element={<DashboardRedux />} />
                {/* You can add other routes if needed, but currently we are focusing only on login */}
            </Routes>
        </div>
    );
}

export default Navigation;
