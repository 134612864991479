import React, { useEffect, useState } from "react";
import Header from "../components/Header"; // Assuming you have a Header component
import Sidebar from "../components/sidebar"; // Assuming you have a Sidebar component
import Rightbar from "../components/rightbar"; // Assuming you have a Rightbar component
import Frame from "../components/cards"; // Assuming you have a Frame component
// import LineChart from "../components/lineChart"; // Assuming you have a LineChart component
// import BoxContainer from "../components/boxContainer"; // Assuming you have a BoxContainer component
// import BarChart from "../components/barChart"; // Assuming you have a BarChart component
import PieChart from "../components/homeCenter"; // Assuming you have a PieChart component
// import Block3 from "../components/block3"; // Assuming you have a Block3 component
// import Block4 from "../components/block4"; // Assuming you have a Block4 component

const Dashboard = ({ DashbordData }) => {
  // console.log("Dashboarddata==>0", Dashboarddata);
  return (
    <div className="dashboard-container">
      <Header />
      <Sidebar Dashboarddata={DashbordData}/>
      <div className="main-content">
        <Rightbar Dashboarddata={DashbordData} />
        <div className="dashboard-content">
            {/* <h1></h1>
            <p></p> */}

          {/* Dashboard Components */}
          <Frame Dashboarddata={DashbordData}/>
          {/* <LineChart/>
          <BoxContainer />
          <BarChart /> */}
          <PieChart Dashboarddata={DashbordData}/>
          {/* <Block3 />
          <Block4 /> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
