import {
  DASHBOARD_DATA_FETCH_REQUEST,
  DASHBOARD_DATA_FETCH_SUCCESS,
  DASHBOARD_DATA_FETCH_FAILURE,
} from "./actionType";

const initialState = {
  fetchedData: false,
  dashboardData: [],
  error: null,
  isLoading: false,
};

const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_DATA_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DASHBOARD_DATA_FETCH_SUCCESS:
      return {
        ...state,
        fetchedData: true,
        dashboardData: action.payload,
        error: null,
        isLoading: false,
      };
    case DASHBOARD_DATA_FETCH_FAILURE:
      return {
        ...state,
        fetchedData: false,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default Dashboard;
