import React, { Component } from 'react';
import LoginPage from '../pages/Login';
import { isEqual } from 'lodash';

export class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
        };
    }

    componentDidMount() { }

    componentDidUpdate(prevProps) {
        const { user, navigate } = this.props
        console.log('update', user)
        if (!isEqual(prevProps.user, user)) {
            console.log('user', user.user)
            if (user.user.success === 1) {
                // alert(user.user.message)
                navigate('/')
            } else {
                alert(user.user.message)
            }
        }
    }


    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    onclicklogin = () => {
        const { email, password } = this.state
        const { loginRequest } = this.props

        loginRequest({
            email,
            password
        })
    }
    render() {
        const { email, password } = this.state
        return (
            <div>
                <LoginPage
                    email={email}
                    password={password}
                    onclicklogin={this.onclicklogin}
                    handleChange={this.handleChange}
                />
            </div>
        );
    }
}

export default Login;
